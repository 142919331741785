async function start() {
  import(/* webpackPreload: true */ '../src/styles/index.scss');
  const [React, { render }, { App }, analytics] = await Promise.all([
    import(/* webpackPreload: true */ 'react'),
    import(/* webpackPreload: true */ 'react-dom'),
    import(/* webpackPreload: true */ './routes/app'),
    import('./analytics')
  ]);

  analytics.init();
  render(<App />, document.getElementById('root'));
}

start();